"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Schema = void 0;
class Schema {
  constructor() {
    this.IDENTIFIER_ONLYERROR = 'onlyError';
    this.IDENTIFIER_401ANDERROR = '401AndError';
    this.IDENTIFIER_401ERRORSUCCESS = '401ErrorAndSuccess';
  }
  getDefaultApiMessages() {
    let identifier = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    let obj = {};
    switch (identifier) {
      case this.IDENTIFIER_ONLYERROR:
        obj = {
          errorMessage: ''
        };
        break;
      case this.IDENTIFIER_401ANDERROR:
        obj = {
          is401Error: false,
          errorMessage: ''
        };
        break;
      case this.IDENTIFIER_401ERRORSUCCESS:
        obj = {
          is401Error: false,
          errorMessage: '',
          successMessage: ''
        };
        break;
      default:
        obj = {
          errorMessage: '',
          successMessage: ''
        };
    }
    return obj;
  }
  getOtpCodeDetails() {
    return {
      message: '',
      requestId: '',
      nextAttemptAt: 0
    };
  }
  getAuthenticatedUserDetails() {
    return {
      id: '',
      type: '',
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      photo_url: '',
      tokens: {
        access_token: '',
        refresh_token: ''
      },
      mb_proxy_token: '',
      userPrivileges: [],
      customRules: {},
      studentTasks: {},
      studentEmplSkills: {},
      college: this.getAuthenticatedCollegeUserDetails(),
      employer: this.getAuthenticatedEmployerUserDetails()
    };
  }
  getAuthenticatedCollegeUserDetails() {
    return this.getCollegeDetails();
  }
  getAuthenticatedEmployerUserDetails() {
    return this.getEmployerDetails();
  }
  getCollegeDetails() {
    return {
      id: '',
      college_types: [],
      college_tier: '',
      college_name: '',
      college_code: '',
      logo_url: '',
      website: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zipcode: '',
      url_slug: '',
      self_signup: '',
      contact_name: '',
      contact_email: '',
      contact_phone: '',
      contact_position: '',
      unclaimed_listing: '',
      claim_college_date: '',
      admin_job_title: '',
      user_custom_login: false
    };
  }
  getEmployerDetails() {
    return {
      id: '',
      business_name: '',
      logo_url: '',
      website: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zipcode: '',
      url_slug: '',
      admin_job_title: ''
    };
  }
  getAccountProfile() {
    return {
      first_name: '',
      middle_name: '',
      last_name: '',
      email: '',
      mobile: '',
      skype_id: '',
      photo_url: '',
      gender: '',
      linkedin_url: '',
      email_bounced: 'N'
    };
  }
  getCollegeDashboard() {
    return {
      college: {
        id: '',
        college_name: '',
        url_slug: '',
        college_code: '',
        college_identifier: '',
        studentsCount: [],
        jobsCount: [],
        employersCount: [],
        degreesOffered: []
      },
      email_bounced: 'N'
    };
  }
  getFullCollegeDetails() {
    return {
      id: '',
      college_name: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      college_types: [],
      college_tier: '',
      zipcode: '',
      website: '',
      student_approval: '',
      self_signup: '',
      auto_employer_approve: '',
      auto_job_approve: '',
      min_ctc_band: '',
      contact_name: '',
      contact_email: '',
      contact_phone: '',
      contact_position: '',
      logo_url: '',
      highest_degree: '',
      ownership: '',
      total_students: '',
      payment_plan: '',
      degrees_offered: [],
      degrees_display: [],
      url_slug: '',
      admin_id: '',
      admin_first_name: '',
      admin_last_name: '',
      admin_email: '',
      admin_mobile: '',
      college_code: '',
      unclaimed_listing: '',
      claim_college_date: '',
      job_title: ''
    };
  }
  getPaginationList() {
    return {
      records_per_page: 0,
      total_records: 0,
      list: []
    };
  }
  getFullEmployerDetails() {
    return {
      id: '',
      business_name: '',
      business_description: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zipcode: '',
      website: '',
      contact_name: '',
      contact_email: '',
      contact_phone: '',
      contact_position: '',
      industry_type: '',
      total_employees: '',
      logo_url: '',
      video_mode: '',
      video_url: '',
      external_video_url: '',
      url_slug: '',
      admin_id: '',
      admin_first_name: '',
      admin_last_name: '',
      admin_email: '',
      admin_mobile: '',
      job_title: '',
      opportunities: [],
      job_functions: [],
      job_locations: [],
      degree_programs: []
    };
  }
  getResendOtpTokenDetails() {
    return {
      message: '',
      resend_otp_token: ''
    };
  }
  getSignupConfirmationDetails() {
    return {
      id: '',
      message: '',
      userDetails: {}
    };
  }
  getAddCollegeDetails() {
    return {
      id: '',
      message: ''
    };
  }
  getAddEmployerDetails() {
    return {
      id: '',
      message: ''
    };
  }
  getAddAssessmentDetails() {
    return {
      id: '',
      message: ''
    };
  }
  getPersonalityAssessmentDetails() {
    return {
      id: '',
      detailed_plans: '',
      clear_goals: '',
      like_everyone: '',
      finish_tasks: '',
      money_motivation: '',
      team_environment: '',
      additional_notes: ''
    };
  }
  getInterestsAssessmentDetails() {
    return {
      id: '',
      realistic: '',
      investigative: '',
      artistic: '',
      social: '',
      enterprising: '',
      conventional: ''
    };
  }
  getValuesAssessmentDetails() {
    return {
      id: '',
      fun: '',
      job_security: '',
      recognition: '',
      compensation: '',
      loyalty: '',
      structure: '',
      independence: '',
      respect: '',
      risk_taking: '',
      variety: '',
      learning: '',
      training_development: '',
      colleague_relationships: '',
      making_difference: '',
      creative_opportunities: ''
    };
  }
  getSkillsAssessmentDetails() {
    return {
      id: '',
      details: {}
    };
  }
  getCompetencyAssessmentDetails() {
    return {
      id: '',
      details: {}
    };
  }
  getAddEmployerJobDetails() {
    return {
      id: '',
      message: ''
    };
  }
  getEmployerJobDetails() {
    return {
      jobId: '',
      employerId: '',
      parentSectionIdentifier: '',
      draftVersionDate: '',
      jobUpdatedDate: '',
      sections: {}
    };
  }
  getCollegeDirectJobDetails() {
    return {
      jobId: '',
      parentSectionIdentifier: '',
      draftVersionDate: '',
      jobUpdatedDate: '',
      sections: {}
    };
  }
  getJobDetails() {
    return {
      id: '',
      directjob_employer_name: '',
      employer_business_name: '',
      job_title: '',
      job_type: '',
      job_function: '',
      job_string: '',
      apply_latest_by: '',
      interview_date: '',
      recruiting_contact: '',
      contact_email: '',
      contact_phone: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zipcode: '',
      website: '',
      no_of_openings: '',
      ctc: '',
      min_ctc_band: '',
      max_ctc_band: '',
      ctc_band_frequency: '',
      ctc_band_currency: '',
      job_location: [],
      responsibilities: '',
      qualification_skills: '',
      additional_info: '',
      applied: '',
      applied_resume_url: '',
      degree_program: [],
      degree_specialization: [],
      college_placement_status: '',
      academic_years: []
    };
  }
  getEmployerDashboard() {
    return {
      employer: {
        id: '',
        business_name: '',
        url_slug: ''
      },
      email_bounced: 'N',
      open_jobs: 0,
      closed_jobs: 0,
      approval_jobs: 0,
      credit_details: {},
      linked_colleges: 0,
      fp_status: false,
      fp_jobs_limit: 0,
      fp_active_jobs: 0
    };
  }
  getPaginationDefault() {
    let multiSelect = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return {
      prevPaginationAndFilter: '',
      p: '1',
      q: '',
      r: '',
      s: '',
      t: '',
      jt: '',
      jf: '',
      dp: '',
      ds: multiSelect.indexOf('ds') !== -1 ? [] : '',
      gy: multiSelect.indexOf('gy_single') !== -1 ? [] : '',
      ity: '',
      ss: multiSelect.indexOf('ss') !== -1 ? [] : '',
      tpr: '',
      emp: '',
      jrd: multiSelect.indexOf('jrd') !== -1 ? [] : '',
      llt: '',
      cle: '',
      mnt: '',
      ev_ty: '',
      ev_ta: '',
      esk: multiSelect.indexOf('esk') !== -1 ? [] : '',
      st: multiSelect.indexOf('st') !== -1 ? [] : '',
      pp: '',
      ccs: '',
      emp_rec: '',
      cum_gpa: '',
      cum_pr: '',
      st_jct: [],
      st_sct: [],
      st_cct: [],
      st_ftj: [],
      st_fte: [],
      st_ftc: [],
      gnd: '',
      sp_ab_di: [],
      cty: '',
      cti: '',
      nlps: ''
    };
  }
  getDeactivateAccount() {
    return {
      message: '',
      redirect_user: false
    };
  }
  getMaintenanceMessageDetails() {
    return {
      type: '',
      message: '',
      start_time: '',
      end_time: ''
    };
  }
  getCollegeStudentsList() {
    return {
      records_per_page: 0,
      total_records: 0,
      list: [],
      balance_email_credits: 0,
      total_pending_students: 0,
      total_employability_skills: 0,
      total_career_pathways: 0
    };
  }
  getAddResumeDetails() {
    return {
      id: '',
      slug: '',
      message: ''
    };
  }
  getResumeDetails() {
    return {
      id: '',
      slug: '',
      profile: {
        video_url: '',
        summary: '',
        personal: {
          first_name: '',
          middle_name: '',
          last_name: '',
          email: '',
          mobile: '',
          skype_id: '',
          photo_url: '',
          gender: '',
          spl_abl_disabilities: [],
          linkedin_url: ''
        },
        other: {
          date_of_birth: '',
          age: 0,
          address_line_1: '',
          address_line_2: '',
          city: '',
          state: '',
          zipcode: '',
          po_box_no: '',
          country: '',
          nationality: '',
          religion: '',
          caste_category: '',
          father_name: '',
          father_email: '',
          father_mobile: '',
          father_occupation: '',
          mother_name: '',
          mother_email: '',
          mother_mobile: '',
          mother_occupation: '',
          pan_no: '',
          aadhaar_no: '',
          passport_no: '',
          residence_phone: ''
        },
        education: [{
          college_id: '',
          college_id_pk: '',
          institution_name: '',
          degree_program: '',
          degree_specialization_1: '',
          degree_specialization_2: '',
          degree_specialization_3: '',
          admission_year: '',
          graduation_year: '',
          cumulative_gpa: '',
          cumulative_percentage: '',
          cumulative_marks: '',
          enrollment_no: ''
        }],
        entrance_exams: [{
          exam_identifier: '',
          maximum_marks: '',
          marks: '',
          rank: ''
        }],
        experience: [{
          title: '',
          company: '',
          location: '',
          from: '',
          to: '',
          description: '',
          additional_info: ''
        }],
        recommendations: [{
          name: '',
          email: '',
          phone: '',
          role: '',
          recommendation: '',
          url: ''
        }],
        skills: [{
          title: '',
          description: '',
          competency: ''
        }],
        interests: [{
          title: '',
          description: ''
        }],
        portfolio: [{
          title: '',
          description: '',
          url: ''
        }],
        extracurricular_activities: '',
        achievements_and_awards: ''
      }
    };
  }
  getStudentAppointmentsDetails() {
    return {
      name: '',
      email: '',
      phone: '',
      degree_program: '',
      degree_specialization: '',
      graduation_date: '',
      enrollment_no: '',
      appointment_reason: '',
      additional_info: '',
      college_types: [],
      college_name: '',
      admin_email: '',
      college_mentor: {},
      intimation_to: ''
    };
  }
  getAddJobSearchStatus() {
    return {
      id: '',
      message: ''
    };
  }
  getStudentJobSearchStatus() {
    return {
      id: '',
      job_type: '',
      job_function: '',
      position_title: '',
      employer_name: '',
      employer_type: '',
      industry_type: '',
      employer_address: '',
      city: '',
      state: '',
      zipcode: '',
      po_box_no: '',
      country: '',
      salary: '',
      amount: '',
      offer_received_on: '',
      start_date: '',
      job_source: '',
      additional_info: '',
      search_status: '',
      updated_at: ''
    };
  }
  getAddJobReadinessDetails() {
    return {
      id: '',
      message: ''
    };
  }
  getJobReadinessDetails() {
    return {
      id: '',
      name: '',
      college_enrollment_no: '',
      degree_program: '',
      readiness_assessment: {
        identify_personality_type: 'N',
        apply_personality_type: 'N',
        identify_career_pathway: 'N',
        clear_career_goals: 'Not Clear',
        improve_self_awareness: 'N',
        understand_job_readiness: 'N',
        resume_available: 'N',
        cover_letter: 'N',
        first_impression: 'N',
        practice_mock_interview: 'N',
        mock_interview_performance: 'Weak',
        rate_search_expectations: 'Low',
        research_career_possibilities: 'N',
        motivation_level: 'Not Motivated & Confident',
        overall_job_readiness: 1
      },
      recommendation_notes: ''
    };
  }
  getViewResumeDetails() {
    return {
      id: '',
      slug: '',
      studentResume: {
        id: '',
        profile: {
          video_url: '',
          summary: '',
          personal: {
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            mobile: '',
            skype_id: '',
            photo_url: '',
            gender: '',
            spl_abl_disabilities: [],
            linkedin_url: ''
          },
          other: {
            date_of_birth: '',
            age: 0,
            address_line_1: '',
            address_line_2: '',
            city: '',
            state: '',
            zipcode: '',
            po_box_no: '',
            country: '',
            nationality: '',
            religion: '',
            caste_category: '',
            father_name: '',
            father_email: '',
            father_mobile: '',
            father_occupation: '',
            mother_name: '',
            mother_email: '',
            mother_mobile: '',
            mother_occupation: '',
            pan_no: '',
            aadhaar_no: '',
            passport_no: '',
            residence_phone: ''
          },
          education: [{
            college_id: '',
            institution_name: '',
            degree_program: '',
            degree_specialization_1: '',
            degree_specialization_2: '',
            degree_specialization_3: '',
            admission_year: '',
            graduation_year: '',
            cumulative_gpa: '',
            cumulative_percentage: '',
            cumulative_marks: '',
            enrollment_no: ''
          }],
          entrance_exams: [{
            exam_identifier: '',
            maximum_marks: '',
            marks: '',
            rank: ''
          }],
          experience: [{
            title: '',
            company: '',
            location: '',
            from: '',
            to: '',
            description: '',
            additional_info: ''
          }],
          recommendations: [{
            name: '',
            email: '',
            role: '',
            recommendation: ''
          }],
          skills: [{
            title: '',
            description: '',
            competency: ''
          }],
          interests: [{
            title: '',
            description: ''
          }],
          portfolio: [{
            title: '',
            description: '',
            url: ''
          }],
          extracurricular_activities: '',
          achievements_and_awards: ''
        }
      }
    };
  }
  getAddCollegeDirectJobDetails() {
    return {
      id: '',
      message: ''
    };
  }
  getStudentDashboard() {
    return {
      student: {
        profile_update_pending: false,
        purchases: [],
        paymentRequiredSignature: ''
      },
      email_bounced: 'N',
      tmp_pwd_unchanged: 'N',
      virtual_college_student: false,
      es_completed_count: 0,
      es_completed_cert: '',
      cp_completed_details: [],
      comp_assmt_score: '',
      job_readiness_score: '',
      esc_details: {}
    };
  }
  getCollegeStudentsSummary() {
    return {
      summary: {
        update_count: 0,
        student_count: 0,
        wait_approval_count: 0,
        details: {}
      }
    };
  }
  getSupportIssues() {
    return {
      student_profile: {}
    };
  }
  getStaffNotifyStudents() {
    return {
      colleges: [],
      count: 0,
      fcm_count: 0
    };
  }
  getCollegeEnrollmentDetails() {
    return {
      id: '',
      enrollment_year: '',
      degree_program: '',
      degree_specialization: '',
      details: {},
      no_of_years: '0'
    };
  }
  getAddCollegeEnrollmentDetails() {
    return {
      id: '',
      message: ''
    };
  }
  getStaffNotifyTpos() {
    return {
      colleges: []
    };
  }
  getTrackAccessUsersList() {
    return {
      records_per_page: 0,
      total_records: 0,
      list: []
    };
  }
  getJobPlacementSummary() {
    return {
      summary: {
        details: {}
      }
    };
  }
  getEmployerCandidateSummary() {
    return {
      summary: {
        details: {}
      },
      search: {
        details: {}
      }
    };
  }
  getEventsWorkshopsDetails() {
    return {
      id: '',
      event_title: '',
      event_start: '',
      event_end: '',
      target_audience: '',
      target_audience_addl: '',
      location: '',
      event_type: '',
      contact_name: '',
      contact_email: '',
      contact_phone: '',
      description: '',
      addl_information: '',
      event_delivery: 'In-Person',
      mkt_event_empl: '',
      mkt_event_instructions: ''
    };
  }
  getEmployerUsageReport() {
    return {
      summary: {}
    };
  }
  getCreditsUsageReport() {
    return {
      balance_email_credits: 0,
      report: []
    };
  }
  getTrackInvoicesReport() {
    return {
      report: []
    };
  }
  getNotificationPreferences() {
    return {
      email: {
        employer_activities: '',
        employer_newsletters: '',
        employer_products: '',
        college_activities: '',
        college_newsletters: '',
        college_products: '',
        student_jobs: '',
        student_newsletters: '',
        student_products: ''
      }
    };
  }
  getApplicantCredits() {
    return {
      records_per_page: 0,
      total_records: 0,
      list: [],
      business_details: {},
      unlimited_credits: false,
      allowed_credits: 0,
      released_credits: 0,
      balance_credits: 0,
      block_views: false
    };
  }
  applyJob() {
    return {
      can_apply: false,
      external_url: '',
      resume_id: '',
      srb_resume: {},
      job_location: []
    };
  }
}
const SchemaInstance = new Schema();
exports.Schema = SchemaInstance;